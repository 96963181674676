import { Hidden, Menu, MenuItem } from '@mui/material';
import { MenuOptionProps } from '../../helpers/interfaces';
import { useNavigate } from 'react-router-dom';
import {
  USER_LOGGED_IN,
  USER_ROLES
} from '../../helpers/constant';
import { useDispatch, useSelector } from 'react-redux';
import { RootStateType } from '../../redux/store';
import { logOutUserProfile } from '../../redux/user/userActions';
import { openSnackbar } from '../../redux/snackbar/snackbarActions';

const MenuOptions: React.FC<MenuOptionProps> = (
  props: MenuOptionProps
): JSX.Element => {
  const navigate = useNavigate();
  const dispatch = useDispatch();

  const userState = useSelector(
    (state: RootStateType) => state.user
  );

  const menuOptions = [
    {
      value: userState.userProfile
        ? userState.userProfile.name
        : '',
      navigateLink: null,
      access_on: USER_LOGGED_IN.YES,
      user_type: 'ANY',
      hide_for_desktop: false
    },
    {
      value: 'Pharmacies',
      navigateLink: '/pharmacies',
      access_on: USER_LOGGED_IN.NO,
      user_type: 'ANY',
      hide_for_desktop: true
    },
    {
      value: 'Services',
      navigateLink: '/services',
      access_on: USER_LOGGED_IN.YES,
      user_type: 'ADMIN',
      hide_for_desktop: true
    },
    {
      value: 'My Pharmacy',
      navigateLink: `/pharmacy/${
        (userState?.myPharmacy ?? [])[0]?.recordId ?? -1
      }`,
      access_on: USER_LOGGED_IN.YES,
      user_type: 'PHARMACY',
      hide_for_desktop: true
    },
    {
      value: 'Requests',
      navigateLink: '/serviceRequests',
      access_on: USER_LOGGED_IN.YES,
      user_type: 'ADMIN',
      hide_for_desktop: true
    },
    {
      value: 'Feedbacks',
      navigateLink: '/giveFeedback',
      access_on: USER_LOGGED_IN.NO,
      user_type: 'ANY',
      hide_for_desktop: true
    },
    {
      value: 'Feedbacks',
      navigateLink: '/feedback',
      access_on: USER_LOGGED_IN.YES,
      user_type: 'ADMIN',
      hide_for_desktop: true
    },
    {
      value: 'Data Ops',
      navigateLink: '/importexport',
      access_on: USER_LOGGED_IN.YES,
      user_type: 'ADMIN',
      hide_for_desktop: true
    },
    {
      value: 'Manage Admins',
      navigateLink: '/manageAdmins',
      access_on: USER_LOGGED_IN.YES,
      user_type: 'SUPERADMIN',
      hide_for_desktop: true
    },
    // {
    //   value: 'Sign Up',
    //   navigateLink: '/signup',
    //   access_on: USER_LOGGED_IN.NO
    // },
    {
      value: 'Sign In',
      navigateLink: '/signin',
      access_on: USER_LOGGED_IN.NO,
      user_type: 'ANY',
      hide_for_desktop: false
    },
    {
      value: 'Reset Password',
      navigateLink: '/resetPassword',
      access_on: USER_LOGGED_IN.YES,
      user_type: 'ANY',
      hide_for_desktop: false
    },
    {
      value: 'Sign Out',
      navigateLink: '/',
      access_on: USER_LOGGED_IN.YES,
      user_type: 'ANY',
      hide_for_desktop: false
    }
  ];

  return (
    <Menu
      anchorEl={props.menuElement}
      anchorOrigin={{
        vertical: 'top',
        horizontal: 'right'
      }}
      id="primary-search-account-menu"
      keepMounted
      transformOrigin={{
        vertical: 'top',
        horizontal: 'right'
      }}
      open={Boolean(props.menuElement)}
      onClose={() => {
        props.setMenuElement(null);
      }}>
      {menuOptions
        .filter(
          (item) =>
            (userState.userProfile &&
              item.access_on === USER_LOGGED_IN.YES &&
              (item.user_type === 'ANY' ||
                (item.user_type === 'ADMIN' &&
                  [
                    USER_ROLES.ADMIN,
                    USER_ROLES.SUPERADMIN
                  ].includes(userState.userProfile.role)) ||
                (item.user_type === 'SUPERADMIN' &&
                  userState.userProfile.role ===
                    USER_ROLES.SUPERADMIN) ||
                (item.user_type === 'PHARMACY' &&
                  userState.userProfile.role ===
                    USER_ROLES.USER))) ||
            (userState.userProfile === null &&
              item.access_on === USER_LOGGED_IN.NO) ||
            item.value === 'Pharmacies'
        )
        .map((item) => {
          const menuItem = (
            <MenuItem
              key={item.value}
              onClick={() => {
                props.setMenuElement(null);
                item.navigateLink
                  ? navigate(item.navigateLink)
                  : null;
                if (item.value === 'Sign Out') {
                  dispatch(logOutUserProfile());
                  dispatch(
                    openSnackbar(
                      'You have logged out successfully!',
                      'success'
                    )
                  );
                }
              }}>
              {item.value}
            </MenuItem>
          );
          return (
            <>
              {item.hide_for_desktop ? (
                <Hidden mdUp>{menuItem}</Hidden>
              ) : (
                menuItem
              )}
            </>
          );
        })}
    </Menu>
  );
};

export default MenuOptions;
