import {
  Avatar,
  Box,
  Button,
  CircularProgress,
  Container,
  CssBaseline,
  Grid,
  ThemeProvider,
  Typography,
  createTheme
} from '@mui/material';
import Copyright from '../../sharedComponents/Copyright/Copyright';
import EditIcon from '@mui/icons-material/Edit';
import { useNavigate, useParams } from 'react-router-dom';
import { useEffect, useState } from 'react';
import {
  DUMMY_PHARMACY,
  PHARMACY_OPERATION_MODE,
  USER_ROLES
} from '../../helpers/constant';
import { PharmacyObject } from '../../helpers/interfaces';
import { getPharmacyByRecordId } from '../../helpers/apiCalls';
import { useDispatch, useSelector } from 'react-redux';
import { RootStateType } from '../../redux/store';
import PharmacyBasicDetails from './PharmacyBasicDetails';
import PharmacyAddress from './PharmacyAddress';
import PharmacyCredentialDetails from './PharmacyCredentialDetails';
import PharmacyReview from './PharmacyReview';
import {
  createPharmacy,
  updatePharmacy
} from '../../redux/pharmacies/pharmaciesActions';
import { openSnackbar } from '../../redux/snackbar/snackbarActions';

const ManagePharmacy: React.FC = (): JSX.Element => {
  const defaultTheme = createTheme();
  const navigate = useNavigate();
  const dispatch = useDispatch();

  const { recordId } = useParams<string>();

  const [operationType, setOperationType] =
    useState<string>(PHARMACY_OPERATION_MODE.NEW);
  const [selectedPharmacy, setSelectedPharmacy] =
    useState<PharmacyObject>(
      DUMMY_PHARMACY as PharmacyObject
    );
  const [progress, setProgress] = useState<number>(1);

  const [basicDetailsWarning, setBasicDetailsWarning] =
    useState<boolean>(true);
  const [addressDetailsWarning, setAddressDetailsWarning] =
    useState<boolean>(true);
  const [
    credentialDetailsWarning,
    setCredentialDetailsWarning
  ] = useState<boolean>(true);

  const [updateComment, setUpdateComment] =
    useState<string>('');
  const [reviewWarning, setReviewWarning] =
    useState<boolean>(true);

  const [sameAdress, setSameAddress] =
    useState<boolean>(false);

  const [loading, setLoading] = useState<boolean>(false);

  const userState = useSelector(
    (state: RootStateType) => state.user
  );

  useEffect(() => {
    if (!recordId) {
      setSelectedPharmacy(DUMMY_PHARMACY);
      setOperationType(PHARMACY_OPERATION_MODE.NEW);
      return;
    }
    const fetch = async () => {
      const pharmacyValue = await getPharmacyByRecordId(
        dispatch,
        Number(recordId)
      );

      let update_mode = false;
      if (
        pharmacyValue &&
        userState.userProfile &&
        [USER_ROLES.ADMIN, USER_ROLES.SUPERADMIN].includes(
          userState.userProfile.role
        )
      ) {
        update_mode = true;
      }
      if (
        pharmacyValue &&
        userState.userProfile?.email ===
          pharmacyValue.facilityEmail
      ) {
        update_mode = true;
      }

      if (update_mode) {
        setSelectedPharmacy(
          pharmacyValue as PharmacyObject
        );
        setOperationType(PHARMACY_OPERATION_MODE.UPDATE);
      } else {
        setSelectedPharmacy(DUMMY_PHARMACY);
        setOperationType(PHARMACY_OPERATION_MODE.NEW);
      }
    };
    fetch();
  }, [recordId, userState]);

  const moveNext = async (
    event: React.MouseEvent<HTMLButtonElement>
  ) => {
    event.preventDefault();
    switch (progress) {
      case 2:
        if (sameAdress) {
          updatePharmacyObject({
            mailingAddress1:
              selectedPharmacy.locationAddress1,
            mailingAddress2:
              selectedPharmacy.locationAddress2,
            mailingCity: selectedPharmacy.locationCity,
            mailingState: selectedPharmacy.locationState,
            mailingCounty: selectedPharmacy.locationCounty,
            mailingZip: selectedPharmacy.locationZip
          });
        }
        setProgress(progress + 1);
        break;
      case 4:
        setLoading(true);
        if (operationType === PHARMACY_OPERATION_MODE.NEW) {
          await createPharmacy(
            dispatch,
            selectedPharmacy,
            userState.userProfile?.userId || -1
          );
          dispatch(
            openSnackbar('Pharmacy created!', 'success')
          );
          navigate('/');
        } else {
          await updatePharmacy(
            dispatch,
            selectedPharmacy,
            updateComment,
            userState.userProfile?.userId || -1
          );
          dispatch(
            openSnackbar('Pharmacy updated!', 'success')
          );
          navigate(`/pharmacy/${recordId}`);
        }
        setLoading(false);
        break;
      default:
        setProgress(progress + 1);
        break;
    }
  };

  const disableNextButton = (): boolean => {
    return (
      (progress === 1 && basicDetailsWarning) ||
      (progress === 2 && addressDetailsWarning) ||
      (progress === 3 && credentialDetailsWarning) ||
      (progress === 4 && reviewWarning)
    );
  };

  const getNextButtonText = (): string => {
    switch (progress) {
      case 1:
        return 'NEXT';
      case 2:
        return 'NEXT';
      case 3:
        return 'REVIEW';
      default:
        return 'SUBMIT';
    }
  };

  const updatePharmacyObject = (
    updates: Record<string, string | number | null>
  ) => {
    setSelectedPharmacy({
      ...selectedPharmacy,
      ...updates
    });
  };

  return (
    <ThemeProvider theme={defaultTheme}>
      <Container
        component="main"
        maxWidth="xs"
        sx={{
          background: '#fff',
          minWidth: '60%',
          paddingBottom: '1%'
        }}>
        <CssBaseline />
        <Box
          sx={{
            marginTop: 2,
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'center'
          }}>
          <Avatar sx={{ m: 1, bgcolor: '#4169e1' }}>
            <EditIcon />
          </Avatar>
          <Typography component="h1" variant="h5">
            {operationType === PHARMACY_OPERATION_MODE.NEW
              ? 'Create new'
              : 'Update'}{' '}
            pharmacy
          </Typography>
          <Box
            component="form"
            noValidate
            onSubmit={() => {}}
            sx={{ mt: 2 }}>
            {progress === 1 && (
              <PharmacyBasicDetails
                permitNo={selectedPharmacy.permitNo}
                pharmacyName={selectedPharmacy.pharmacyName}
                phone={selectedPharmacy.phone}
                fax={selectedPharmacy.fax}
                website={selectedPharmacy.website}
                operationHours={
                  selectedPharmacy.operationHours
                }
                pharmacyType={selectedPharmacy.pharmacyType}
                updatePharmacyObject={updatePharmacyObject}
                setWarning={setBasicDetailsWarning}
                viewMode="NORMAL"
              />
            )}
            {progress === 2 && (
              <PharmacyAddress
                locationAddress1={
                  selectedPharmacy.locationAddress1
                }
                locationAddress2={
                  selectedPharmacy.locationAddress2
                }
                locationCity={selectedPharmacy.locationCity}
                locationState={
                  selectedPharmacy.locationState
                }
                locationZip={selectedPharmacy.locationZip}
                locationCounty={
                  selectedPharmacy.locationCounty
                }
                mailingAddress1={
                  selectedPharmacy.mailingAddress1
                }
                mailingAddress2={
                  selectedPharmacy.mailingAddress2
                }
                mailingCity={selectedPharmacy.mailingCity}
                mailingState={selectedPharmacy.mailingState}
                mailingZip={selectedPharmacy.mailingZip}
                mailingCounty={
                  selectedPharmacy.mailingCounty
                }
                updatePharmacyObject={updatePharmacyObject}
                setWarning={setAddressDetailsWarning}
                sameAddress={sameAdress}
                setSameAddress={setSameAddress}
                viewMode="NORMAL"
              />
            )}
            {progress === 3 && (
              <PharmacyCredentialDetails
                facilityEmail={
                  selectedPharmacy.facilityEmail
                }
                pmpicName={selectedPharmacy.pmpicName}
                pmpicEmail={selectedPharmacy.pmpicEmail}
                loggedInUsername={
                  userState.userProfile?.name || ''
                }
                loggedInEmail={
                  userState.userProfile?.email || ''
                }
                setWarning={setCredentialDetailsWarning}
                updatePharmacyObject={updatePharmacyObject}
                operationType={operationType}
                viewMode="NORMAL"
              />
            )}
            {progress === 4 && (
              <PharmacyReview
                pharmacy={selectedPharmacy}
                loggedInUsername={
                  userState.userProfile?.name || ''
                }
                loggedInEmail={
                  userState.userProfile?.email || ''
                }
                updatePharmacyObject={updatePharmacyObject}
                operationType={operationType}
                sameAddress={sameAdress}
                setSameAddress={setSameAddress}
                setWarningAddress={setAddressDetailsWarning}
                setWarningBasic={setBasicDetailsWarning}
                setWarningCredentails={
                  setCredentialDetailsWarning
                }
                updateComment={updateComment}
                setUpdateComment={setUpdateComment}
                setWarningReview={setReviewWarning}
              />
            )}
            <Grid container spacing={2}>
              <Grid
                item
                xs={12}
                sm={12}
                md={
                  progress === 1 &&
                  operationType ===
                    PHARMACY_OPERATION_MODE.UPDATE
                    ? 6
                    : 4
                }>
                <Button
                  type="submit"
                  fullWidth
                  variant="contained"
                  color="error"
                  sx={{ mt: 3, mb: 2 }}
                  onClick={(
                    event: React.MouseEvent<HTMLButtonElement>
                  ) => {
                    event.preventDefault();
                    navigate(`/pharmacy/${recordId}`);
                  }}>
                  Cancel
                </Button>
              </Grid>
              {(progress !== 1 ||
                operationType !==
                  PHARMACY_OPERATION_MODE.UPDATE) && (
                <Grid item xs={12} sm={12} md={4}>
                  <Button
                    type="submit"
                    fullWidth
                    variant="contained"
                    sx={{ mt: 3, mb: 2 }}
                    onClick={(
                      event: React.MouseEvent<HTMLButtonElement>
                    ) => {
                      event.preventDefault();
                      setProgress(progress - 1);
                    }}>
                    PREVIOUS
                  </Button>
                </Grid>
              )}
              <Grid
                item
                xs={12}
                sm={12}
                md={
                  progress === 1 &&
                  operationType ===
                    PHARMACY_OPERATION_MODE.UPDATE
                    ? 6
                    : 4
                }>
                <Button
                  type="submit"
                  fullWidth
                  variant="contained"
                  color={
                    progress < 5 ? 'primary' : 'success'
                  }
                  sx={{ mt: 3, mb: 2 }}
                  onClick={(
                    event: React.MouseEvent<HTMLButtonElement>
                  ) => {
                    moveNext(event);
                  }}
                  disabled={loading || disableNextButton()}>
                  {loading ? (
                    <CircularProgress />
                  ) : (
                    getNextButtonText()
                  )}
                </Button>
              </Grid>
            </Grid>
          </Box>
        </Box>
        <Copyright />
      </Container>
    </ThemeProvider>
  );
};

export default ManagePharmacy;
